import React from 'react'
import './Homepage.css'
import { useState } from 'react'
import { useEffect } from 'react'
import axios from 'axios'


export default function Footer() {

  const [setting, setSetting] = useState([])  
  
  useEffect(()=>{
    let url=(process.env.REACT_APP_SETTING_ROOT)
    axios.get(url).then((res)=>{
      setSetting(res.data)
    }).catch((err)=>{
      console.log(err)
    })
  },[])

  return (
    <div className="footer">
        <div className="container">
          <div className="footer-setup">
          <div className="row">
            {
              setting?.map((setting)=>{
                return(
                  <>
                          <div className="col-md-3">
                            <div className="footer-content">
                            <img src={setting.acf.footer_logo} alt="footer Logo" />
                            </div>
                            
                          </div>
                          <div className="col-md-3">
                            <div className="footer-content">
                            <h4>{setting.acf.footer_second_title}</h4>
                            
                            </div>
                            
                          </div>
                          <div className="col-md-3">
                            <div className="footer-content">
                            <h4>{setting.acf.footer_services_title}</h4>
                            
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="footer-content">
                            <h4>{setting.acf.footer_carrers_title}</h4>
                            
                            </div>
                          </div>
                  
                  </>
                )
              })
            }


            </div>
          </div>
        
        </div>
    </div>
  )
}
