
import './App.css';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import Homepage from './homepage/Homepage';

function App() {
  return (
    <div className="App">
      <>
      <Homepage/>
      </>
    </div>
  );
}

export default App;
