import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import './Components.css'



export default function Nav() {
    const [sticky , setSticky] = useState(false)

    useEffect(()=>{
        const handleScrool=()=>{
            setSticky(window.scrollY>150)
        }
        window.addEventListener('scroll',handleScrool)
        return()=>{
            window.removeEventListener('scroll',handleScrool)
        }
    },[])

  return (
    <>
        <div className="herowraper">
        <nav class={`navbar navbar-expand-lg fixed-top nav-back ${sticky?'sticky':''}`} >
        <div class="container-fluid">
            <a class="navbar-brand" href="#">

            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto ms-auto mb-2 mb-lg-0 " >
                <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="/">SERVICES</a>
                </li>
                <li class="nav-item">
                <a class="nav-link" href="#">PROJECTS</a>
                </li>
                <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    SERVICES
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li><a class="dropdown-item" href="#">Action</a></li>
                    <li><a class="dropdown-item" href="#">Another action</a></li>
                    <li><hr class="dropdown-divider"/></li>
                    <li><a class="dropdown-item" href="#">Something else here</a></li>
                </ul>
                </li>
                <li class="nav-item">
                <a class="nav-link " href="#" tabindex="-1" aria-disabled="true">ABOUT US</a>
                </li>
                <li class="nav-item">
                <a class="nav-link " href="#" tabindex="-1" aria-disabled="true">CONTACT US</a>
                </li>
            </ul>
    
            </div>
        </div>
        </nav>

   
        </div>
   
    
    </>
  )
}
