import React from 'react'
import './Homepage.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { StarFill } from 'react-bootstrap-icons';
export default function Review() {

  const [review, setReview] = useState([])

  useEffect(()=>{
    let url=(process.env.REACT_APP_REVIEW_ROOT)
    axios.get(url).then((res)=>{
      setReview(res.data)
    }).catch(err=>{
      console.log(err)
    })
  },[]);
  
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        autoplay: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    }

  return (
    <div className="review">
        <div className="container">
            <div className="review-title-main">
                <h1> Top Review </h1>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro ut, laboriosam illo tempora esse rem sunt! Accusantium sit molestiae, eos optio reprehenderit nam quis id nemo natus tempore veritatis voluptatem?</p>
            </div>
            
            <div className="row">
                <Slider{...settings}> 
                {
                    review?.map((review)=>{
                        return(
                          <>
                           <div className="review-slider">
                             <div className="review-control">

                          
                             <div className="review-all">

                               <div className="review-icon">
                                  <img src={review.acf.reviewicon} alt={review.title.rendered} className='img-review'/>
                                </div>
                                <div className="review-title">
                                <h5>{review.title.rendered}</h5>
                              
                                <span className='review-stars'> 
                                  <StarFill/>
                                  <StarFill/>
                                  <StarFill/>
                                  <StarFill/>
                                  <StarFill/>
                                </span>
                                
                                </div>
                             </div>
                            
                             
                               <span className='review-content' dangerouslySetInnerHTML={{__html: review.content.rendered}}/>
                               </div>
                            </div>
                          </>
                        )
                    })
                  }

                </Slider>
            </div>
        </div>
    </div>
  )
}
