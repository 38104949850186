import React from 'react'
import Nav from '../components/Nav'
import Homebanner from '../components/Homebanner'
import HomeVideo from './HomeVideo'
import Development from './Development'
import Projects from './Projects'
import Step from './Step'
import Review from './Review'
import Footer from './Footer'

export default function Homepage() {
  return (
    <>
    <Nav/>
    <Homebanner/>
    <HomeVideo/>
    <Development/>
    <Projects/>
    <Step/>
    <Review/>
    <Footer/>
    </>
  )
}
