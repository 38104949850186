import axios from 'axios'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import './Homepage.css'
import { CupHotFill } from 'react-bootstrap-icons'
export default function Step() {
    const[step, setStep]= useState([])

    useEffect(()=>{
        let url=(process.env.REACT_APP_DEV_STEP_ROOT)
        axios.get(url).then((res)=>{
            setStep(res.data)
        }).catch(err=>{
            console.log(err)
        })
    },[])
  return (
    <>
    <div className="step">
            <div className="container">
                <div className="step-title">
                <h1>Simple Process</h1>
                </div>
                <div className="row">
                    {
                        step?.map((step)=>{
                            return(
                                <>
                                   <div key={step.id} className="col-md-2">
                                       <p className='icon-step'>
                                           <span className='icon-dev'><CupHotFill/></span>
                                        </p>
                                        <h3>{step.title.rendered}</h3>
                                    </div>
                                
                                </>
                            )
                        })
                    }
                 

                </div>
            </div>
        </div>
    </>
    
  )
}
