import axios from 'axios'
import './Homepage.css'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { CheckCircle } from 'react-bootstrap-icons'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';

export default function Projects() {
    const [project, setProject] = useState([])
    const [setting, siteSetting] = useState([])

    useEffect(()=>{
        let url =(process.env.REACT_APP_PROJECT_ROOT)
        axios.get(url).then((res)=>{
            setProject(res.data)
        }).catch(err=>{
            console.log(err)
        })
    },[])

    useEffect(()=>{
      let url=(process.env.REACT_APP_SETTING_ROOT)
      axios.get(url).then((res)=>{
        siteSetting(res.data)
      }).catch(err=>{
        console.log(err)
      })
    },[])

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        autoplay: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    }
  return (
    <>
    <div className="projects">
        <div className="container">
            <div className="row">
            {
              setting?.map((setting)=>{
                return(
                  <>
                      <div key={setting.id} className="col-md-4">
                        <div className="project-name">
                            <h1>{setting.acf.project_title}</h1>
                            <strong className='proj-description'>{setting.acf.project_description} </strong>
                        <div className="projectname">
                        <p> <CheckCircle/>{setting.acf.project_types_1} </p>
                        <p><CheckCircle/> {setting.acf.project_types_2} </p>
                        <p> <CheckCircle/> {setting.acf.project_types_3} </p>
                        <p><CheckCircle/>  {setting.acf.project_types_4} </p>
                        </div>
                    </div>
                </div>
                  
                  </>
                )
              })
            }
            

                <div className="col-md-8">
                <Slider{...settings}> 
                {
                    project?.map((project)=>{
                        return(
                            <>
                            <div className="slide-control">
                                <div key={project.id} className='project-slide'>
                                    <img src={project.fimg_url} alt={project.title.rendered} className='img-fluid' />
                                        <h5> {project.title.rendered}</h5>
                                        
                                    <span className='project-short'> {project.acf.short_description} </span>
                                </div>
                            </div>
                            
                            </>
                        )
                    })
                }
                  
                    
                </Slider>
             
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
