import React from 'react'
import './Homepage.css'
import { useState } from 'react'
import { useEffect } from 'react'
import axios from 'axios'

export default function Development() {
    const [development, setDevelopment] = useState([])

    useEffect(()=>{
        let url=(process.env.REACT_APP_DEVELOPMENT_ROOT)
        axios.get(url).then((res)=>{
            setDevelopment(res.data)
        }).catch(err=>{
            console.log(err)
        })
    },[])

  return (
    <>
    <div className="development">
        <div className="container">
            <h1> Development Platform </h1>
            <div className="row">
                {
                    development?.map((development)=>{
                        return(
                            <>
                                <div className="col-md-4">
                                    <div className="dev-platform">
                                        <div className="dev-icon">
                                        <img src={development.acf.icon} alt="" className='img-fluid' />
                                        </div>
                                       
                                        <h3> {development.title.rendered}</h3>
                                        <span className='dev-short' dangerouslySetInnerHTML={{__html: development.acf.short_description}} />
                                    </div>
                                </div>
                            </>
                        )
                    })
                }
          
            </div>
        </div>
    </div>
    </>
  )
}
