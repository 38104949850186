import React from 'react'
import './Components.css'
import { CheckCircle } from 'react-bootstrap-icons'
import { useState } from 'react'
import { useEffect } from 'react'
import axios from 'axios'
export default function Homebanner() {
    const [hero, setHero] = useState([])

    useEffect(()=>{
        let url=(process.env.REACT_APP_ROOT_HOME_HERO_API)
        axios.get(url).then((res)=>{
            setHero(res.data)
        }).catch(err=>{
            console.log(err)
        })
        
    },[])
  return (
    <>
        <div className="homebanner">
            <div className="container-fluid">
                <div className="homehero">
                    
                        {
                            hero?.map((hero)=>{
                                return(
                                    <>
                                    <div key={hero.id} className="row">
                                       <div className="col-md-6">
                                        <div className="hero-text">
                                            <h1>{hero.title.rendered} </h1>
                                            <span className='herosubtitle'> {hero.acf.sub_title}  </span>
                                            <ul className='herofeature'>
                                                <li className='herofeturelist'> <CheckCircle/> {hero.acf.options_1}</li>
                                                <li className='herofeturelist'> <CheckCircle/> {hero.acf.option_2}</li>
                                                <li className='herofeturelist'><CheckCircle/>  {hero.acf.option_3} </li>
                                                <li className='herofeturelist'> <CheckCircle/> {hero.acf.option_4}</li>
                                            </ul>
                                        <div className="hero-button">
                                            <span className='hero-button-text'>See all Plan </span>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                    <div className="ban-feature">
                                        <img src={hero.acf.bannerimage} alt="" className='img-fluid' />
                                    </div>
                                    </div>
                                       
                                    </div>
                                    </>
                                )
                            })
                        }
                  
                </div>
               
            </div>
        </div>
    </>
  )
}
