import React from 'react'
import './Homepage.css'
import { useState } from 'react'
import { useEffect } from 'react'
import axios from 'axios'
import { Star } from 'react-bootstrap-icons'
export default function HomeVideo() {
    const [video, setVideo] = useState([])
    
    useEffect(()=>{
        let url=(process.env.REACT_APP_HOME_VIDEO_API)
        axios.get(url).then((res)=>{
            setVideo(res.data)
        }).catch(err=>{
            console.log(err)
        })
    },[])
  return (
    <>
    <div className="homevideo">
        <div className="container">
            {
                video?.map((video)=>{
                    return(
                        <>
                            <div key={video.id} className="row">

                            <div className="col-md-6">
                                <div className="singlevideo">
                          
                                    <div class="ratio ratio-16x9">
                                    <iframe class="embed-responsive-item" src={video.acf.video_link} allowfullscreen></iframe>
                                    
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">

                                <div className="video-title">
                                    <h1> {video.title.rendered}</h1>
                                <span className='videocontent' dangerouslySetInnerHTML={{__html: video.content.rendered}}/> 
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <span className='star'>
                                        <Star/> <Star/> <Star/> <Star/>
                                        </span><br/>
                                        <span className='starcount'> {video.acf.review_1_star} </span>
                                        <br/>
                                        <span className='review-platform'> {video.acf.review_platform} </span>
                                    </div>

                                    <div className="col-sm-4">
                                    <span className='star'>
                                        <Star/> <Star/> <Star/> <Star/>
                                        </span><br/>
                                        <span className='starcount'> {video.acf.review_2_stars} </span>
                                        <br/>
                                        <span className='review-platform'> {video.acf.review_2_platform} </span>
                                    </div>

                                    <div className="col-sm-4">
                                        3
                                    </div>
                                </div>
                            </div>
                            </div>
                        </>
                    )
                })
            }
       
        </div>
    </div>
    
    </>
  )
}
